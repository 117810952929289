import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Card from "../components/newComponents/card";
import SectionHead from "../components/newComponents/sectionHead";
import Pagination from "../components/newComponents/pagination";

export const data = graphql`
  query ResourcesQuery($limit: Int, $skip: Int) {
    resources: allCraftResourcesResourcesEntry(sort: {fields: postDate, order: DESC}, limit: $limit, skip: $skip) {
      nodes {
        displayTitle
        uri
        summary
        featureImage {
        ...AssetFragment
        }
      }
    }
  }
`;

const ResourceListing = ({data: {resources}, pageContext}) => (
  <Layout pageContext={pageContext}>
    <Seo title="Reports and other documents" />
    <div className="main">
      <section className="section-boxes section-boxes--news section-boxes--news-secondary">
        <div className="shell">
          <SectionHead title="Reports and other documents" />
          <div className="boxes boxes--news swiper-container js-slider-boxes-news">
            <ul>
              {resources.nodes.map((item, i) => (
                <Card 
                  key={`reports-and-other-documents-listing-${i}`} 
                  ar={2} image={item.featureImage[0]} 
                  title={item.displayTitle} 
                  subtitle={item.summary} 
                  href={`/${item.uri}`}
                />
              ))}
            </ul>
            <div className="swiper-pagination boxes__pagination" />
          </div>
          {pageContext.totalPages > 1 && (
            <Pagination nextUrl={pageContext.nextUrl} prevUrl={pageContext.prevUrl} currentPage={pageContext.currentPage} base="/reports-and-other-documents" totalPages={pageContext.totalPages}/>
          )}
        </div>
      </section>
    </div>
  </Layout>
);

export default ResourceListing;